import React from 'react'
import './gallerycard.css'


class GalleryCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            colorStart: this.getRandomColor(),
            colorEnd: this.getRandomColor()
        }
    }

    getRandomColor(){    
        let r = Math.floor(Math.random()*255);
        let g = Math.floor(Math.random()*255);
        let b = Math.floor(Math.random()*255);
        let a = 0.9;
        return 'rgba('+ r +','+ g +','+ b + ',' + a +')';
    }

    componentDidMount() {
        document.querySelectorAll('.gallery-text label').forEach((elem)=>{
            elem.style.backgroundClip = 'text';
            elem.style.webkitBackgroundClip = 'text';
        });
    }

    render() {
        return (
            <div className="GalleryCardGroup">
                <div className="apple-style-gallery">
                    <div className="gallery-frame" style={{"gridArea": "left-top"}}>
                        <img src={this.props.imgurl0} alt="" draggable="false" loading="lazy"/>
                    </div>
                    <div className="gallery-frame gallery-top" style={{"gridArea": "top"}}>
                        <div><img src={this.props.imgurl1} alt="" draggable="false" loading="lazy"/></div>
                    </div>
                    <div className="gallery-frame gallery-right-top" style={{"gridArea": "right-top"}}>
                        <div><img src={this.props.imgurl2} alt="" draggable="false" loading="lazy"/></div>
                        <div><img src={this.props.imgurl3} alt="" draggable="false" loading="lazy"/></div>
                    </div>
                    <div className="gallery-frame gallery-text" style={{"gridArea": "text"}}>
                        <div><p>{this.props.text_1}<label style={{ 
                            background: `linear-gradient(125deg, ${this.state.colorStart} 0%, ${this.state.colorEnd} 100%)`,
                        }}>{this.props.text_2}</label>{this.props.text_3}</p></div>
                        <div><p>{this.props.text_en_1}<label style={{ 
                            background: `linear-gradient(125deg, ${this.state.colorStart} 0%, ${this.state.colorEnd} 100%)`,
                        }}>{this.props.text_en_2}</label>{this.props.text_en_3}</p></div>
                    </div>
                    <div className="gallery-frame gallery-center-topic" style={{
                        "gridArea": "center-topic",
                        background: `linear-gradient(125deg, ${this.state.colorStart} 0%, ${this.state.colorEnd} 100%)`,
                    }}>
                        <h1>{this.props.topic}</h1>
                    </div>
                    <div className="gallery-frame gallery-icon" style={{"gridArea": "icon"}}>
                        <div><img src={ this.props.avatar } alt="" draggable="false" loading="lazy"/></div>
                    </div>
                    <div className="gallery-frame gallery-left-bottom-1" style={{"gridArea": "left-bottom-1"}}>
                        <div><img src={this.props.imgurl4} alt="" draggable="false" loading="lazy"/></div>
                        <div><img src={this.props.imgurl5} alt="" draggable="false" loading="lazy"/></div>
                    </div>
                    <div className="gallery-frame" style={{"gridArea": "left-bottom-2"}}>
                        <img src={this.props.imgurl6} alt="" draggable="false" loading="lazy"/>
                    </div>
                    <div className="gallery-frame gallery-left-bottom-3" style={{"gridArea": "left-bottom-3"}}>
                        <div><img src={this.props.imgurl7} alt="" draggable="false" loading="lazy"/></div>
                    </div>
                    <div className="gallery-frame" style={{"gridArea": "bottom"}}>
                        <img src={this.props.imgurl8} alt="" draggable="false" loading="lazy"/>
                    </div>
                    <div className="gallery-frame" style={{"gridArea": "right-bottom"}}>
                        <img src={this.props.imgurl9} alt="" draggable="false" loading="lazy"/>
                    </div>
                </div>
            </div>
        )
    }
}

export default GalleryCard
