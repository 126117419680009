import React from 'react'

import styled from 'styled-components'
import Wave from '../components/wave'
import './wavesection.css'

import Typing from '../lib/typing.js'
import '../lib/typing.css'

class WaveSection extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            typing_delay: 250,
            typing_objs: []
        }

    }

    handleTyping = () => {
        let texts = document.getElementsByClassName("WaveSectionText")
        for(let index=0;index<texts.length;index++){
            let text = texts[index]
            let buf_text = document.createElement("div")
            buf_text.innerHTML = text.innerHTML
            text.innerHTML = "";
            var typing = new Typing({
                source: buf_text,
                output: text,
                delay: this.state.typing_delay,
                done: function() {

                } //完成打印后的回调事件
            });
            typing.start()
            this.setState({ typing_objs: [...this.state.typing_objs, typing] })
        }
    }

    componentDidMount(){
        if(!(/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent))){
            this.handleTyping()  //初始时调用一次

            if(this.props.onWaveSectionChange != undefined) {
                this.props.onWaveSectionChange(this)    //父组件更新时调用Typing
            }
        }

        let that = this;
        window.addEventListener('focus', function() {
            // iterate typing_objs and call typing.resume()
            that.state.typing_objs.forEach(typing => {
                typing.resume()
            });
            
        });
        window.addEventListener('blur', function() {
            that.state.typing_objs.forEach(typing => {
                typing.pause()
            });
        });


        // document.querySelectorAll('.WaveSectionGroup').forEach(group => {
        //     let bg = group.firstElementChild
        //     let logo = group.lastElementChild.previousElementSibling
        //     let text = group.lastElementChild

        //     let speed = 0.5

        //     group.addEventListener('mouseover', function (e) { 
                
        //     })

        //     group.addEventListener('mousemove', function(e) {

        //         let x = (window.innerWidth - e.pageX * speed) / 100
        //         let y = (window.innerHeight - e.pageY * speed) / 100

        //         bg.style.transform = `scaleX(1.05) scaleY(0.95) translateX(${x}px)`
        //         // logo.style.transform = `translateX(${x}px) translateY(${y+30}px)`
        //         // text.style.transform = `translateX(${-x}px) translateY(${-y-30}px)`
        //     })

        //     group.addEventListener('mouseout', function(e) {
        //         bg.style.transform = `scaleX(1.05) scaleY(0.95) translateX(0px)`
        //         // logo.style.transform = `translateX(0px) translateY(0px)`
        //         // text.style.transform = `translateX(0px) translateY(0px)`
        //     })
        // })
    }


    render() {
        return (
            // <div className="WaveSectionGroup" style={{ backgroundImage: "url(" + this.props.image + ")"}}>
            <div className="WaveSectionGroup">
                <img className="WaveSectionbg" id="WaveSectionbg" src={this.props.image} loading="eager" />
                <div className="WaveTop">
                    {/* <Wave waveheight="120" /> */}
                    <Wave />
                </div>
                <div className="WaveBottom">
                    {/* <Wave waveheight="120" /> */}
                    <Wave />
                </div>
                <img className="WaveSectionLogo" src={this.props.logo} draggable="false" loading="eager" />
                <div className="WaveSectionTitleGroup">
                    <p className="Decoration left">/*</p>
                    <p className="WaveSectionText" id="wave-section-text">{this.props.text}</p>
                    {/* <span class="typing-cursor-white">|</span> */}
                    <p className="Decoration right">*/</p>
                </div>
            </div>
        )
    }
}

export default WaveSection