import React from 'react'
import './sectioncaption.css'

const SectionCaption = data => (
    <div className="SectionCaptionGroup">
        <h2><label>{data.title}</label>{data.emoji}</h2>
        <h3>{data.subtitle}</h3>
    </div>
)

export default SectionCaption