import React from 'react'
import './gallery.css'

import GalleryCard from './gallerycard.js'
import YoyoAvatar from '../../images/avatar/yoyo-avatar.jpg'

class Gallery extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            rate: 0,
            counter: props.data.length,
        };
    }

    componentDidMount(){
        document.querySelectorAll(".apple-style-gallery").forEach((gallery, index) => {
            gallery.style.left = index*120 + "%";
        });

        document.getElementById("gallery-right-arrow").addEventListener("click", ()=>{
            this.setState({ rate: this.state.rate + 1 });
            if(this.state.rate == this.state.counter){
                this.setState({ rate: this.state.counter - 1 })

                this.noMoreCallBack("gallery-right-arrow");
            }
            this.galleryTransformCallBack();
        });

        document.getElementById("gallery-left-arrow").addEventListener("click", ()=>{
            this.setState({ rate: this.state.rate - 1 });
            if(this.state.rate < 0) {
                this.setState({ rate: 0 })

                this.noMoreCallBack("gallery-left-arrow");
            }
            this.galleryTransformCallBack();
        });

        let slideUpdateTimeInterval = 35000;
        setInterval(()=>{
            this.setState({ rate: (this.state.rate + 1) % this.state.counter });
            this.galleryTransformCallBack();
        }, slideUpdateTimeInterval);
    }

    noMoreCallBack(id){
        var arrow = document.getElementById(id);
        arrow.style.animation = "noMoreAnimation 1s";
        arrow.addEventListener("animationend", () => {
            arrow.style.animation = "";
        });
    }

    galleryTransformCallBack(){
        document.querySelectorAll(".apple-style-gallery").forEach((gallery)=>{
            gallery.style.transform = "translateX(calc(" + this.state.rate*-120 + "%))";
        });
    }

    render() {
        return (
            <div className="GalleryGroup">
                <div className="gallery-container">
                    {this.props.data.map((edge, index) => (
                        <GalleryCard 
                            key={index}
                            topic={edge.node.topic}
                            text_1={edge.node.text1}
                            text_2={edge.node.text2}
                            text_3={edge.node.text3}
                            text_en_1={edge.node.textEn1}
                            text_en_2={edge.node.textEn2}
                            text_en_3={edge.node.textEn3}
                            imgurl0={edge.node.album[0].fluid.src}
                            imgurl1={edge.node.album[1].fluid.src}
                            imgurl2={edge.node.album[2].fluid.src}
                            imgurl3={edge.node.album[3].fluid.src}
                            imgurl4={edge.node.album[4].fluid.src}
                            imgurl5={edge.node.album[5].fluid.src}
                            imgurl6={edge.node.album[6].fluid.src}
                            imgurl7={edge.node.album[7].fluid.src}
                            imgurl8={edge.node.album[8].fluid.src}
                            imgurl9={edge.node.album[9].fluid.src}
                            avatar={!edge.node.withYoyo ? this.props.avatars[index % this.props.avatars.length].node.img.fluid.src : YoyoAvatar}
                        />
                    ))}
                </div>
                
                <div id="gallery-left-arrow" className="arrow left">&lt;</div>

                <div id="gallery-right-arrow" className="arrow right">&gt;</div>
            </div>
        )
    }
}

export default Gallery