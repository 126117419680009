import React from 'react'

import './hero.css'
import Wave from './wave.js'
import WaveMulti from './wavemulti.js'

// import HeroBg from '../../images/hero/hero-bg.jpg'
// import YoyoBg from '../../images/hero/yoyo-bg.jpg'
// import NotFoundBg from '../../images/hero/404-bg.jpg'


class Hero extends React.Component {
    constructor(props) {
        super(props)

        let hero_node =this.props.data[0].node;
        if (this.props.data.length > 1 && this.props.data[0].node.name=="张喆｜doubleZ") {
            let bg_color = this.getDynamicHeroColor()
            for (let i=0; i<this.props.data.length; i++){
                if(this.props.data[i].node.color == bg_color) {
                    hero_node = this.props.data[i].node;
                    break;
                }
            }
        } else {
            hero_node = this.props.data[0].node;
        }
        

        this.state = {
            hero_node: hero_node
        }

    }

    getDynamicHeroColor() {
        let date = new Date();
        let options = {
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        timeZone: 'Asia/Shanghai',
        hour12: false,
        timeZoneName: 'short'
        };
        let chinaDate = new Intl.DateTimeFormat('en-AU', options).format(date);
        let hour=chinaDate.slice(0,2), min=chinaDate.slice(3,5)

        let bg_color = "";
        if(hour>=4 && hour<8) {
            bg_color = "green";
        } else if((hour<22 && hour>=8) || (hour==22 && min<6)) {
            bg_color = "blue";
        } else {
            bg_color = "red";
        }

        return bg_color
    }

    componentDidMount() {
        const speed = 1

        let hero = document.getElementById("Hero")
        let bg = hero.firstElementChild
        let text = hero.firstElementChild.nextElementSibling.firstElementChild

        hero.addEventListener('mouseover', function (e) { 
            
        })

        hero.addEventListener('mousemove', function (e) { 

            let x = (window.innerWidth - e.pageX * speed) / 100
            let y = (window.innerHeight - e.pageY * speed) / 100

            bg.style.transform = `scale(1.1) translateX(${x}px) translateY(${y}px)`
            text.style.transform = `translateX(${-x}px) translateY(${-y}px)`

        })

        let hero_node = NaN;
        if (this.props.data.length > 1 && this.props.data[0].node.name=="张喆｜doubleZ") {
            let bg_color = this.getDynamicHeroColor()
            for (let i=0; i<this.props.data.length; i++){
                if(this.props.data[i].node.color == bg_color) {
                    hero_node = this.props.data[i].node;
                    break;
                }
            }
        }
        this.setState({ hero_node: hero_node });
    }

    render() {

        return (
            // <div className="Hero" style={{ backgroundImage: `url(${this.props.data.bg.fluid.src})` }} loading="eager">
            <div className="Hero" id="Hero">
            {/* <div className="Hero" style={{ backgroundImage: `url(${ this.state.pageBg })` }} loading="eager"> */}
                <img className="HeroBG" src={this.props.data[0].node.bg.fluid.src} loading="eager" draggable="false" />
                <div className="HeroGroup">
                    <div className="HeroTextGroup">
                        <h1>{this.props.data[0].node.name}</h1>
                        <h3>{this.props.data[0].node.description}</h3>
                        <blockquote>{this.props.data[0].node.quote}</blockquote>
                    </div>
                </div>
                <WaveMulti />
            </div>
        )
    }
}

export default Hero